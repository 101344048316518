// General
import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

//  Ui-kit
import Button from 'ui-kit/button/button';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import PageSection from 'ui-kit/page-section/page-section';
import Spinner from 'ui-kit/spinner/spinner';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
// Components
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import { CartShipping } from 'components/cart/cart-shipping.component';
import { CartTotal } from 'components/cart/cart-total/cart-total.component';
import HealthConditions from 'components/cart/health-conditions/health-conditions.component';
import { PrescriptionInformation } from 'components/cart/review/prescription-information.component';
import CartLayout from 'components/layouts/cart/cart.layout';
import PaymentMethods from 'components/payment-methods/payment-methods.component';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';

// States
import { accountFetchHealthConditionsRoutine, accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountHasInsuranceSelector,
    accountIsMembershipSelector,
    accountPlansSelector,
    accountProfileAddressesSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import { CreditCardPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    cancelOrderLine,
    cartCompleteOrderRoutine,
    cartUpdateExpeditedShippingRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine,
    getCartRoutine
} from 'state/cart/cart.routines';
import {
    cartIsBusySelector,
    cartOrderBillShipMethodSelector,
    cartOrderBillShipSelector,
    cartOrderHighPriority,
    cartOrderShippingAddressSelector,
    cartSelector,
    cartSubtotalSelector
} from 'state/cart/cart.selectors';
import { ExtendedCartObjectPayload, StartOrderV2Payload } from 'state/cart/cart.services';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import {
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from 'state/medical-conditions/medical-conditions.routines';
import {
    isLoadingAllergiesSelector,
    isLoadingHealthConditionsSelector
} from 'state/medical-conditions/medical-conditions.selector';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

// Types
import { OrderBillShip, RefillRxs } from 'types/order-prescription';
import { PaymentMethodsProps } from 'types/payment';
import { RxDetails } from 'types/prescription';

import { isAxiosError } from 'util/axiosClient';
// Util
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID, processCart } from 'util/cart';
import { TrackCheckoutStep, TrackInputError } from 'util/google_optimize/optimize_helper';

//  Hooks
import { useHealthConditions } from 'hooks/useHealthConditions';

// Modal content
import UpdateCartModalContent, { FailureUpdateCartModalContent } from '../intra-page-items/_cart-update-modal-item';
// Styles
import './review.style.scss';

const ReviewOrder = ({ data }: { data: GatsbyTypes.CartReviewDataQuery }) => {
    // General
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const birdiPricePrescriptions: RxDetails[] = [];

    // States
    const [currentShippingPrice, setCurrentShippingPrice] = useState(0);
    const [errorStatus, setErrorStatus] = useState(false);
    const [cartIsEmpty, setCartIsEmpty] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCartBusy, setIsCartBusy] = useState(false);
    const [cartPaymentCardSeqNumValid, setCartPaymentCardSeqNumValid] = useState(false);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const [drugDiscountPricesFetched, setDrugDiscountPricesFetched] = useState(false);
    const [cartPageTracked, setCartPageTracked] = useState(false);
    const [prescriptionsInCart, setPrescriptionsInCart] = useState<RxDetails[] | undefined>(undefined);

    // Selector
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const cartObject = useSelector(cartSelector);
    const [hasUnknownPrice, setHasUnknownPrice] = useState(false);
    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload[] | undefined>(undefined);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const cartSubtotal = useSelector(cartSubtotalSelector);
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const isCaregiver = useSelector(accountProfilIsCaregiverSelector);
    const highPriority = useSelector(cartOrderHighPriority);
    const profileObject = useSelector(accountProfileSelector);
    const isMembership = useSelector(accountIsMembershipSelector);
    const isLoadingHealthConditions = useSelector(isLoadingHealthConditionsSelector);
    const isLoadingAllergies = useSelector(isLoadingAllergiesSelector);
    const cardOrderShippingAddress = useSelector(cartOrderShippingAddressSelector);
    const isLoadingCartItems = useSelector(cartIsBusySelector);

    const { existingAllergies, existingConditions, userHasNotSubmittedAllergies, userHasNotSubmittedConditions } =
        useHealthConditions(profileObject?.hasMembership);

    useEffect(() => {
        if (profileObject) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());

            if (profileObject?.hasMembership) {
                dispatch(
                    medicalConditionsDetailsRoutine.trigger({
                        epostPatientNumFamily: profileObject?.epostPatientNum
                    })
                );
                dispatch(
                    medicalConditionsAllergiesDetailsRoutine.trigger({
                        epostPatientNumFamily: profileObject?.epostPatientNum
                    })
                );
            } else {
                dispatch(accountFetchHealthConditionsRoutine.trigger());
            }
        }
    }, [profileObject, dispatch]);

    const getDrugDiscountPrice = async (birdiPricePrescriptions: RxDetails[]) =>
        new Promise((resolve, reject) => {
            // do anything here
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: true,
                    location: 'Cart',
                    onSuccess: () => {
                        resolve('success');
                    }
                })
            );
        });

    const trackCartStep2 = () => {
        if (!cartPageTracked) {
            TrackCheckoutStep({
                stepName: 'checkout',
                step: '2',
                carts: extendedCartObject as ExtendedCartObjectPayload[],
                prescriptions: prescriptionsObject,
                t: t,
                shippingCost: `${currentShippingPrice}`,
                accountHasInsurance: accountHasInsurance
            });
            setCartPageTracked(true);
        }
    };

    useEffect(() => {
        setIsCartBusy(isLoadingAllergies || isLoadingHealthConditions);
    }, [isLoadingHealthConditions, isLoadingAllergies]);

    // This effect checks if the cart is empty and set setCartIsEmpty true or false
    useEffect(() => {
        const cartItemsObject = extendedCartObject?.flatMap((item) => item.extendedRefillRxs).filter((item) => !!item);

        if (cartItemsObject?.length) {
            if (
                !drugDiscountPricesFetched &&
                (!accountHasInsurance || profileObject?.hasMembership || ALLOW_INSURED_BIRDI_PRICE)
            ) {
                // Get The Birdi Price
                cartItemsObject.forEach((item: any) => {
                    birdiPricePrescriptions.push(item.prescriptionDetail);
                });

                getDrugDiscountPrice(birdiPricePrescriptions).then(() => {
                    setDrugDiscountPricesFetched(true);
                    trackCartStep2();
                });
            } else {
                if (prescriptionsObject.length > 0 && accountPlans && accountPlans.length > 0) {
                    trackCartStep2();
                }
            }
            setCartIsEmpty(false);
        } else {
            setCartIsEmpty(true);
            if (extendedCartObject) {
                trackCartStep2();
            }
        }

        setHasUnknownPrice(extendedCartObject?.some((item) => item.itemHasUnknownPrice) ?? false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extendedCartObject]);

    useEffect(() => {
        // Since the drug prices API is called separately, we are currently validating against the prescriptions
        // already present in the cart. This ensures consistency in our approach by only fetching prescriptions
        // for the current user and refreshing the state when each tab is called. Please note:
        // This validation serves as a temporary solution, providing a safeguard against potential side effects
        // that may impact pricing results it should be reviewed for future implementations.
        async function prescriptionsInCart(cart: StartOrderV2Payload[]) {
            const prescriptionsInCart: RxDetails[] = [];

            cart.forEach((orderPayload: StartOrderV2Payload) => {
                if (orderPayload.Order.refillRxs && orderPayload.Order.refillRxs.length > 0) {
                    orderPayload.Order.refillRxs.forEach((refillRx: RefillRxs) => {
                        if (refillRx.prescriptionDetail) {
                            prescriptionsInCart.push(refillRx.prescriptionDetail);
                        }
                    });
                }
            });

            setPrescriptionsInCart(prescriptionsInCart);

            return prescriptionsInCart;
        }

        async function getCartOrder() {
            if (cartObject) {
                const extendedCart = processCart(
                    cartObject,
                    accountHasInsurance,
                    isCaregiver ? await prescriptionsInCart(cartObject) : prescriptionsObject,
                    drugDiscountPrices,
                    accountPlans,
                    isMembership,
                    cardOrderShippingAddress?.zipcode || ''
                );

                // Adding this condition to fix DRX-2889 because this setExtendedCartObject
                // was causing unstoppable api calls to pricing. However, this is just
                // a patch, final fix will require a refactor.
                if (JSON.stringify(extendedCart) !== JSON.stringify(extendedCartObject)) {
                    setExtendedCartObject(extendedCart);
                    setCurrentShippingPrice(highPriority ? EXPEDITED_SHIPPING_COST : 0);
                }
            }
        }
        getCartOrder();
    }, [cartObject, accountHasInsurance, drugDiscountPrices, accountPlans, cardOrderShippingAddress?.zipcode]);

    useEffect(() => {
        if (errorStatus) {
            TrackInputError(null, 'Form Submit', t('pages.reviewOrder.errors.orderError'), 'ReviewOrder');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorStatus]);

    const allPaymentData = useSelector(accountCreditCardsSelector);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const orderBillShip = useSelector(cartOrderBillShipSelector);

    const addresses: AddressCardProps[] = profileAddresses.map((address) => {
        const cartCurrentShippingSeqNum =
            !isCaregiver && orderBillShip?.dependentAddressSeqNum
                ? orderBillShip?.dependentAddressSeqNum
                : orderBillShip?.patientShipAddressSeq;
        const isCurrentCartShippingAddress = address.addressSeqNum === cartCurrentShippingSeqNum;
        return {
            addressSeqNum: address.addressSeqNum,
            defaultAddress: address.defaultShip,
            isChecked: isCurrentCartShippingAddress,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            country: address.country,
            state: address.state,
            zipcode: address.zipcode,
            zipcodeFour: address.zipcodeFour,
            defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
            addressType: address.addressTypeDesc,
            isProfile: false
        };
    });

    useEffect(() => {
        if (allPaymentData && orderBillShip) {
            if (!orderBillShip.paymentCardSeqNum) {
                // Set the cardSeqNum to the default card and update
                let defaultCard: CreditCardPayload | undefined = undefined;
                if (allPaymentData !== undefined && allPaymentData.length > 0) {
                    if (allPaymentData.length === 1) {
                        defaultCard = allPaymentData[0];
                    } else {
                        defaultCard = allPaymentData.find((obj: { defaultCard: any }) => obj.defaultCard);
                    }
                }
                if (defaultCard) {
                    const updatedPayment = {
                        ...orderBillShip,
                        paymentCardSeqNum: defaultCard.cardSeqNum
                    };
                    dispatch(
                        cartUpdatePaymentRoutine.trigger({
                            ...updatedPayment
                        })
                    );
                }
            }
        }

        if (allPaymentData?.length && orderBillShip?.paymentCardSeqNum) {
            const selectedCard = allPaymentData.find(
                (card: { cardSeqNum: string }) => card.cardSeqNum === orderBillShip.paymentCardSeqNum
            );
            const dependantSelectedCard = allPaymentData.find(
                (card: { cardSeqNum: string }) => card.cardSeqNum === orderBillShip.dependentPaymentSeqNum
            );
            if (selectedCard || dependantSelectedCard) {
                setCartPaymentCardSeqNumValid(true);
            } else {
                setCartPaymentCardSeqNumValid(false);
            }
        } else {
            setCartPaymentCardSeqNumValid(false);
        }
    }, [allPaymentData, orderBillShip]);

    const handleShipToAddressClick = (address: AddressCardProps) => {
        setIsCartBusy(true);

        const editAddress = profileAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        const updatedAddress = {
            ...orderBillShip,
            patientBillAddressSeq: editAddress?.addressSeqNum,
            patientShipAddressSeq: editAddress?.addressSeqNum,
            dependentAddressSeqNum: isCaregiver ? orderBillShip?.dependentAddressSeqNum : editAddress?.addressSeqNum
        };

        dispatch(
            cartUpdateShippingRoutine.trigger({
                ...updatedAddress,
                onSuccess: () => {
                    setDrugDiscountPricesFetched(false);
                    dispatch(
                        getCartRoutine.trigger({
                            onSuccess: () => {
                                setIsCartBusy(false);
                                dispatch(
                                    openModal({
                                        showClose: true,
                                        className: 'prescription-modal',
                                        bodyContent: (
                                            <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />
                                        ),
                                        ctas: [
                                            {
                                                label: t('modals.updateCart.labels.gotIt'),
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                },
                                                dataGALocation: 'ReviewOrderUpdateCart'
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                },
                onFailure: () => {
                    setIsCartBusy(false);
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCartError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handlePaymentMethodSelectionChange = React.useCallback<
        NonNullable<PaymentMethodsProps['onCardSelectionChange']>
    >(
        (newSelectedCard) => {
            setIsCartBusy(true);

            if (!newSelectedCard || !orderBillShip) {
                return;
            }

            const updatedOrderBillShip: OrderBillShip = {
                ...orderBillShip,
                paymentCardSeqNum: newSelectedCard.cardSeqNum,
                dependentPaymentSeqNum: isCaregiver ? orderBillShip.dependentPaymentSeqNum : newSelectedCard.cardSeqNum
            };

            dispatch(
                cartUpdatePaymentRoutine.trigger({
                    ...updatedOrderBillShip,
                    onSuccess: () => {
                        dispatch(
                            getCartRoutine.trigger({
                                onSuccess: () => {
                                    setIsCartBusy(false);
                                    dispatch(
                                        openModal({
                                            showClose: true,
                                            bodyContent: (
                                                <BirdiModalContent
                                                    icon={'success'}
                                                    title={t('modals.paymentMethods.success.title')}
                                                    body={t('modals.paymentMethods.success.description')}
                                                />
                                            ),
                                            ctas: [
                                                {
                                                    label: t('modals.paymentMethods.success.cta'),
                                                    variant: 'primary',
                                                    onClick: () => {
                                                        dispatch(closeModal({}));
                                                    },
                                                    dataGALocation: 'ReviewOrderUpdateCart'
                                                }
                                            ]
                                        })
                                    );
                                }
                            })
                        );
                    },
                    onFailure: () => {
                        setIsCartBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: (
                                    <FailureUpdateCartModalContent area={t('modals.updateCart.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCartError'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, orderBillShip, isCaregiver, t]
    );

    const handleSubmitOrderClick = () => {
        setErrorStatus(false);
        setIsSubmitting(true);
        dispatch(
            cartCompleteOrderRoutine({
                currentShippingPrice: Number(currentShippingPrice),
                lineItems: extendedCartObject?.flatMap((rx) => rx.extendedRefillRxs).filter((item) => !!item) || [],
                onSuccess: () => {
                    setIsSubmitting(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: extendedCartObject as ExtendedCartObjectPayload[],
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: `${currentShippingPrice}`,
                        accountHasInsurance: accountHasInsurance
                    });
                    navigate('/secure/cart/order-confirmation');
                },
                onFailure: (data: unknown) => {
                    setIsSubmitting(false);
                    setErrorStatus(true);
                    if (
                        data &&
                        typeof data === 'object' &&
                        'response' in data &&
                        isAxiosError(data.response) &&
                        data.response.response?.data.SystemMessage === 'PRE-AUTH-DENIED'
                    ) {
                        showPaymentError();
                    }
                }
            })
        );
    };

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleDefaultShippingClick = () => {
        setIsCartBusy(true);
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: false,
                shipMethodId: DEFAULT_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(
                        getCartRoutine.trigger({
                            onSuccess: () => setIsCartBusy(false),
                            onFailure: () => setIsCartBusy(false)
                        })
                    );
                    setCurrentShippingPrice(0);
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(
                        getCartRoutine.trigger({
                            onSuccess: () => setIsCartBusy(false),
                            onFailure: () => setIsCartBusy(false)
                        })
                    );
                    return false;
                }
            })
        );
    };

    const handleExpeditedShippingClick = () => {
        setIsCartBusy(true);
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: true,
                shipMethodId: EXPEDITED_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(
                        getCartRoutine.trigger({
                            onSuccess: () => setIsCartBusy(false),
                            onFailure: () => setIsCartBusy(false)
                        })
                    );
                    setCurrentShippingPrice(EXPEDITED_SHIPPING_COST);
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(
                        getCartRoutine.trigger({
                            onSuccess: () => setIsCartBusy(false),
                            onFailure: () => setIsCartBusy(false)
                        })
                    );
                    return false;
                }
            })
        );
    };

    const handleRemoveOrder = (rxNumber: string) => {
        setIsCartBusy(true);
        setCartPageTracked(false);

        dispatch(
            cancelOrderLine({
                rxNumber,
                onSuccess: () => {
                    setIsCartBusy(false);
                    TrackCheckoutStep({
                        stepName: 'remove',
                        step: '1',
                        carts: extendedCartObject as ExtendedCartObjectPayload[],
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: `${currentShippingPrice}`,
                        accountHasInsurance: accountHasInsurance
                    });

                    if (
                        extendedCartObject &&
                        extendedCartObject?.flatMap((item) => item.extendedRefillRxs).filter((item) => !!item).length <=
                            1
                    ) {
                        dispatch(getCartRoutine.trigger());
                    }
                },
                onFailure: () => {
                    showCartError();
                    setIsCartBusy(false);
                }
            })
        );
    };

    const showCartError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.cart')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const showPaymentError = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.paymentMethods.paymentError.title')} icon="alert" />
                ),
                bodyContent: (
                    <BirdiModalErrorContent
                        description={t('modals.paymentMethods.paymentError.description')}
                        ctaText={t('modals.paymentMethods.paymentError.cta')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    }, [dispatch, t]);

    const defaultCreditCard = React.useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0
            ? allPaymentData.find((card) => card.defaultCard)
            : undefined;
    }, [allPaymentData]);

    return (
        <CartLayout
            backgroundImage={data.backgroundImage}
            nodeTitle={t('pages.reviewOrder.title')}
            title={t('pages.reviewOrder.title')}
            eyebrowText={t('pages.reviewOrder.eyebrowText')}
            variant="review"
            isHideHeader={!!cartIsEmpty}
        >
            {!cartIsEmpty || isSubmitting ? (
                <div className="cart-review">
                    <Spinner isVisible={isCartBusy} t={t} />
                    <CartReviewSectionHeader label={t('components.medicineCabinetCart.shippingMethod.title')} />
                    <CartShipping
                        handleDefaultShippingClick={handleDefaultShippingClick}
                        handleExpeditedShippingClick={handleExpeditedShippingClick}
                        shipMethodId={shipMethodId}
                        t={t}
                    />
                    <CartReviewSectionHeader
                        label={t('pages.cart.prescriptionInformation')}
                        pricingLabel={t('pages.cart.pricing')}
                    />
                    <PrescriptionInformation
                        t={t}
                        fullCart={extendedCartObject || []}
                        prescriptions={prescriptionsInCart as RxDetails[]}
                        isCaregiver={isCaregiver}
                        accountHasInsurance={accountHasInsurance}
                        onRemoveOrder={handleRemoveOrder}
                        isMembership={isMembership}
                    />
                    <Container fluid>
                        <Row>
                            <Col>
                                <Button
                                    type="button"
                                    variant="text-blue"
                                    className="p-0 mt-4 text-uppercase add-prescriptions"
                                    label={t('pages.cart.addMorePrescriptions')}
                                    onClick={() => {
                                        navigate('/secure/medicine-cabinet');
                                    }}
                                    dataGAFormName="addMorePrescriptions"
                                    chevron="right"
                                    style={{
                                        lineHeight: '1.5'
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <CartTotal
                        t={t}
                        hasUnknownPrice={hasUnknownPrice || !!isMembership}
                        initialOrderPrice={`${cartSubtotal}`}
                        currentShippingPrice={shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0}
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.shippingAddress')} />
                    <ShippingAddresses
                        addressData={addresses}
                        onSetAsShipping={handleShipToAddressClick}
                        isProfile={false}
                        showLabels={true}
                        addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                        buttonVariant="text-blue"
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.paymentInformation')} />
                    <PaymentMethods
                        withNewPaymentButton
                        isProfile={false}
                        showLabel
                        onCardSelectionChange={handlePaymentMethodSelectionChange}
                        paymentData={allPaymentData ? allPaymentData : []}
                        paymentRequiredMessage={t('pages.reviewOrder.paymentMethodRequired')}
                        selectedCardSeqNum={
                            isCaregiver
                                ? orderBillShip?.paymentCardSeqNum || defaultCreditCard?.cardSeqNum
                                : orderBillShip?.dependentPaymentSeqNum || defaultCreditCard?.cardSeqNum
                        }
                        showSelectCardRadioInput
                        showSetDefaultLink
                    />
                    <CartReviewSectionHeader label={t('pages.profile.healthProfile.labels.healthProfile')} />
                    <HealthConditions
                        existingConditions={existingConditions}
                        existingAllergies={existingAllergies}
                        hasHealthCondtionsError={userHasNotSubmittedConditions}
                        hasAllergiesError={userHasNotSubmittedAllergies}
                        isMembershipHealthConditions={profileObject?.hasMembership}
                    />
                    <div className="cart-review__actions">
                        <Button
                            type="button"
                            className="cart-submit-button"
                            label={t('pages.reviewOrder.submit')}
                            onClick={handleSubmitOrderClick}
                            dataGAFormName="OrderPrescription"
                            dataGAFormStepName="Checkout"
                            disabled={
                                orderBillShip?.paymentCardSeqNum === null ||
                                orderBillShip?.patientBillAddressSeq === null ||
                                orderBillShip?.patientShipAddressSeq === null ||
                                !cartPaymentCardSeqNumValid ||
                                userHasNotSubmittedConditions ||
                                userHasNotSubmittedAllergies ||
                                isSubmitting
                            }
                        />
                        <Button
                            type="button"
                            variant="text"
                            className="text-cerulean cart-review__actions--back"
                            label={t('pages.reviewOrder.backToCart')}
                            onClick={() => {
                                navigate('/secure/medicine-cabinet');
                            }}
                            dataGAFormName="OrderPrescription"
                            dataGAFormStepName="Checkout"
                        />

                        <div className="cart-total__disclaimer">
                            {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {cartIsEmpty && !isLoadingCartItems ? (
                        <PageSection className="page-section__white">
                            <Container fluid>
                                <Row className="justify-content-center">
                                    <Col
                                        className="text-center d-flex flex-column justify-content-center align-items-center"
                                        xs={12}
                                        md={6}
                                    >
                                        <CartIcon className={'cart-icon__circle'} />
                                        <h1>{t('pages.reviewOrder.emptyCart.title')}</h1>
                                        <h3>{t('pages.reviewOrder.emptyCart.subtitle')}</h3>
                                        <div className="spacer" />
                                        <Button
                                            type="button"
                                            className="sm-full btn-bold"
                                            label={t('pages.reviewOrder.emptyCart.submit')}
                                            onClick={() => navigate('/secure/medicine-cabinet')}
                                            dataGALocation="Your shopping cart is empty."
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </PageSection>
                    ) : (
                        <Spinner isVisible={isLoadingCartItems} t={t} />
                    )}
                </>
            )}
        </CartLayout>
    );
};

export default ReviewOrder;

export const query = graphql`
    query CartReviewData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        successModalPillImageMobile: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
