import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit
import BirdiAccordion from 'ui-kit/accordion/accordion';
import Button from 'ui-kit/button/button';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import {
    AllergiesModalContent,
    HealthConditionsModalContent
} from 'components/health-profile/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';

// Modal
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { getPhoneNumber } from 'util/globalVariables';

import './health-conditions.style.scss';

export interface HealthConditionsProps {
    existingConditions: string[];
    existingAllergies: string[];
    isMembershipHealthConditions?: boolean;
    isLoadingHealthConditions?: boolean;
    isLoadingAllergies?: boolean;
    hasAllergiesError?: boolean;
    hasHealthCondtionsError?: boolean;
}

interface HealthConditionsButtonProps {
    onClick: () => void;
    label: string;
}

const HealthConditionsAddConditionButton = ({ label, onClick }: HealthConditionsButtonProps) => (
    <div className="cart__health-conditions__link">
        <Button
            plusIcon
            IconType="secondary"
            className="p-0"
            dataGAFormName="healthConditions"
            label={label}
            onClick={onClick}
            type="button"
            variant="text-blue"
        />
    </div>
);

const HealthConditionsAddAllergiesButton = ({ label, onClick }: HealthConditionsButtonProps) => (
    <div className="cart__health-conditions__link">
        <Button
            plusIcon
            IconType="secondary"
            className="p-0"
            dataGAFormName="allergies"
            label={label}
            onClick={onClick}
            type="button"
            variant="text-blue"
        />
    </div>
);

const HealthConditions = ({
    existingConditions,
    existingAllergies,
    isMembershipHealthConditions,
    isLoadingHealthConditions,
    isLoadingAllergies,
    hasAllergiesError = false,
    hasHealthCondtionsError = false
}: HealthConditionsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isHealthProfileIncomplete = (existingConditions && existingAllergies).length === 0;

    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                        isMembershipHealthConditions={isMembershipHealthConditions}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'primary',
                size: 'lg',
                className: 'modal-health-profile-warning',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                        },
                        dataGALocation: 'ReviewOrderHealthConditions'
                    }
                ]
            })
        );
    };

    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                        isMembershipHealthConditions={isMembershipHealthConditions}
                    />
                ),
                ctas: []
            })
        );
    };

    return (
        <div className="cart__health-conditions">
            <Container fluid>
                <Row>
                    <Col>
                        {isMembershipHealthConditions && isHealthProfileIncomplete ? (
                            <ToastBox variant="warning" icon="warning">
                                <Trans
                                    i18nKey={'pages.requestTelemedicineVisit.healthProfile.healthProfileConfirmation'}
                                />
                            </ToastBox>
                        ) : (
                            <div
                                className="cart__health-conditions__text"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(
                                        t('pages.reviewOrder.healthProfileConfirmation', {
                                            phoneNumber: getPhoneNumber({ isEnd: true })
                                        })
                                    )
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <h4 className="cart__health-conditions__title">{t('accordions.healthConditions.title')}</h4>
                    </Col>
                    <Col className="text-right d-none d-lg-flex justify-content-lg-end">
                        <HealthConditionsAddConditionButton
                            label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                            onClick={handleToggleHealthConditionsClick}
                        />
                    </Col>
                </Row>
                {hasHealthCondtionsError ? (
                    <Row>
                        <Col>
                            <div className="error">
                                {t('pages.requestTelemedicineVisit.healthProfile.errors.conditionsRequired')}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col>
                            {isLoadingHealthConditions ? (
                                <SpinnerInline />
                            ) : (
                                <HealthConditionPills conditions={existingConditions} />
                            )}
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="text-center d-block d-lg-none">
                        <HealthConditionsAddConditionButton
                            label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                            onClick={handleToggleHealthConditionsClick}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BirdiAccordion.Spacer />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="cart__health-conditions__title">{t('accordions.allergies.title')}</h4>
                    </Col>
                    <Col className="text-right d-none d-lg-flex justify-content-lg-end">
                        <HealthConditionsAddAllergiesButton
                            label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                            onClick={handleToggleAllergiesClick}
                        />
                    </Col>
                </Row>
                {hasAllergiesError ? (
                    <Row>
                        <Col>
                            <div className="error">
                                {t('pages.requestTelemedicineVisit.healthProfile.errors.allergiesRequired')}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-1">
                        <Col>
                            {isLoadingAllergies ? (
                                <SpinnerInline />
                            ) : (
                                <HealthConditionPills conditions={existingAllergies} />
                            )}
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="text-center d-block d-lg-none">
                        <HealthConditionsAddAllergiesButton
                            label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                            onClick={handleToggleAllergiesClick}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HealthConditions;
